<template>
  <div class="unqualified">
    <van-nav-bar
      title="今日不良"
      left-text="返回"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <scroll class="product_scroll" :onLoadMore="onLoadMore">
      <div class="output_k" v-if="todayBadReportWork.length == 0">
        <div class="conten_empty_img">
          <img src="../../common/img/kong.png" />
        </div>
        <div class="establish">
          今天还没有报工任务哦，快去
          <a @click="toWorkOrder()">
            <router-link to="">新建报工 </router-link>
          </a>
          吧!
        </div>
      </div>
      <div v-else>
        <div
          class="unqualified_item"
          v-for="item in todayBadReportWork"
          :key="item.id"
        >
          <!--          <div class="unqualified_item_text">-->
          <!--            {{ item.defectiveItemText }}-->
          <!--          </div>-->
          <div class="unqualified_item_name">
            <div>产品名称:</div>
            <div>{{ item.productName }}</div>
          </div>
          <div class="unqualified_item_content">
            <div class="content_item">
              <div>不良品数</div>
              <div>{{ item.badProductCounts }}</div>
            </div>
            <div class="content_item">
              <div>占比</div>
              <div>{{ item.bad }}%</div>
            </div>
          </div>
        </div>
      </div>
    </scroll>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import { mapGetters } from "vuex";
import { getTodayBadReportWork } from "@/api/work";

export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      todayBadReportWork: [],
    };
  },
  created() {
    ChangePageTitle("今日不良");
    this.getTodayBadReportWork();
  },
  methods: {
    //跳转去新建工单
    toWorkOrder() {
      window.location.href = "/reportWork1";
    },
    onClickLeft() {
      this.$router.push("/");
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    async getTodayBadReportWork() {
      var params = {
        departmentCode: this["departmentCode"],
      };
      const res = await getTodayBadReportWork(params);
      const dataInfo = res.records;
      const temp = [];
      if (dataInfo.length > 0) {
        for (let i = 0; i < dataInfo.length; i++) {
          if (
            dataInfo[i].badProductCounts > 0 &&
            dataInfo[i].woPlanCounts > 0
          ) {
            temp[i] = {
              bad: (
                (parseInt(dataInfo[i].badProductCounts) /
                  parseInt(
                    dataInfo[i].woPlanCounts == 0 ? 1 : dataInfo[i].woPlanCounts
                  )) *
                100
              ).toFixed(2),
              ...dataInfo[i],
            };
          } else {
            temp[i] = {
              bad: 0,
              ...dataInfo[i],
            };
          }
        }
      }
      this.todayBadReportWork = temp;
    },
  },
  components: {
    scroll,
  },
};
</script>
<style lang="less" scoped>
.unqualified {
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;

  .product_scroll {
    padding-top: 100px;

    .output_k {
      width: 100vw;

      .conten_empty_img {
        margin: auto;
        width: 40vw;
        margin-top: 30%;

        img {
          width: 100%;
        }
      }

      .establish {
        font-size: 30px;
        text-align: center;
        line-height: 1;
        color: #999;
        margin-top: 40px;

        a {
          color: blue;
        }
      }
    }

    .unqualified_item {
      width: 95%;
      margin: auto;
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      padding: 16px 20px;
      font-size: 30px;

      .unqualified_item_text {
        color: black;
      }

      .unqualified_item_name {
        display: flex;
        align-items: center;
        margin-top: 20px;

        div:neth-child(2) {
          margin-left: 5px;
        }
      }

      .unqualified_item_content {
        display: flex;
        margin-top: 20px;

        .content_item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: start;

          div:nth-child(2) {
            margin-top: 10px;
            color: red;
          }
        }
      }
    }
  }
}
</style>
